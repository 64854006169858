.loading-container{
    @apply w-full
    h-[100vh]
    fixed
    z-[4000]
    flex
    justify-center
    items-center
    bg-[#1e1e1e]
    text-white
    font-bold
}