@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  @apply font-serif;
  padding:0px;
  margin:0px;
}

